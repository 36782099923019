<!-- 封装自定义下来菜单组件 -->
<template>
  <div class="c-dropdown">
    <div class="dropdown dropdown-demo" v-if="dropdownType == 'dropdown'">
      <!-- <template v-for="placement in placements" :key="placement"> -->
      <a-dropdown :placement="dropdownPlacement" :trigger="['hover']">
        <slot name="title">
          <!-- <a-button>{{ placement }}</a-button> -->
        </slot>
        <template #overlay>
          <slot name="cmenus"></slot>
        </template>
        <!-- <template #overlay>
            <a-menu>
              <a-menu-item>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.alipay.com/"
                >
                  1st menu item
                </a>
              </a-menu-item>
              <a-menu-item>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.taobao.com/"
                >
                  2nd menu item
                </a>
              </a-menu-item>
              <a-menu-item>
                <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
                  3rd menu item
                </a>
              </a-menu-item>
            </a-menu>
          </template> -->
      </a-dropdown>
      <!-- <br v-if="index === 2" /> -->
      <!-- </template> -->
    </div>
    <div
      class="dropdown-button dropdown-demo"
      v-if="false && dropdownType == 'dropdownButton'"
    >
      <template v-for="placement in placements" :key="placement">
        <a-dropdown :placement="placement">
          <a-button>{{ placement }}</a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.alipay.com/"
                >
                  1st menu item
                </a>
              </a-menu-item>
              <a-menu-item>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.taobao.com/"
                >
                  2nd menu item
                </a>
              </a-menu-item>
              <a-menu-item>
                <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
                  3rd menu item
                </a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <!-- <br v-if="index === 2" /> -->
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps({
  dropdownType: {
    type: String,
    default: "dropdown",
  },
  dropdownPlacement: {
    type: String,
    default: "bottomLeft",
  },
});

const placements = ref([
  "bottomLeft",
  "bottom",
  "bottomRight",
  "topLeft",
  "top",
  "topRight",
]);
</script>
<style lang="scss" scoped>
.c-dropdown {
  // border: 2px solid red;
}
.dropdown-demo .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
:global(.ant-dropdown-menu-item:hover,){
  background-color: #222738 !important;
}


</style>
