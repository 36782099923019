import { defineStore } from "pinia";
import userApi from '@/api/user.js';
import { clone } from 'lodash';
// import {loginModal} from '@/components/common/login/fnlogin.js'
import {loginModal} from '@/components/common/login'
import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;

const defaultUserInfo = {
  avatar: '',//用户头像
  name: '',//昵称
  mobile: '',//手机号
  bean: '',//金币余额
  diamond: '',//钻石余额
}

const user = defineStore({
  id: 'user'
  , state: () => ({
    count:1,
    userInfo: clone(defaultUserInfo),
    isToken: false,
    navi:0,//当前选中的导航栏
    sp:false,
    activeItem:false//背包饰品是否可用--false 则显示全部饰品,为true显示可用饰品,即道具status为0 和 9 的道具
  }),
  actions: {
    //获取用户信息
    getUserInfo() {
      return userApi.getPerInfo().then(res => {
        if (res.code != 200) {
          //用户信息获取失败
          console.log('过期');
          return this.resetUserData()
        }
        this.userInfo = res.message
        return Promise.resolve(res.message)
      })
    },

    setToken(token = '') {
      console.log('setToken method:', token);
      if (token == '') {
        localStorage.removeItem('token')
        this.isToken = false
        //未登录显示登录框
        loginModal({visible:true,title: $t('loginLarge'), contentType: "def"},(callnack)=>{
          // console.log('未登录 弹登录框');
          callnack()
        })
      } else {
        this.isToken = true
        localStorage.setItem('token', token)
        this.loginAfter()
      }
    },

    //更新用户信息
    async updateUserData() {
      if (!this.isToken) {//未登录
        return this.resetUserData()
      }
      await this.getUserInfo()
      return this.userInfo

    },

    //重置用户数据
    resetUserData() {
      this.setToken()
      this.userInfo = clone(defaultUserInfo)
    },

    //登录后处理
    async loginAfter() {
      await this.updateUserData()
    },

    //登出
    async logout(force = false) {
      if (!force) {
        localStorage.removeItem('token')
        this.resetUserData()
        this.count=0
      }
      return !this.isToken
    }

  },
  persist: {
    key: 'user-store', // 自定义存储的键名
    storage: window.localStorage, // 使用localStorage 作为存储位置
	}
  // persist:{
  //   key: 'user-store', // 自定义存储的键名
  //   storage: window.localStorage, // 使用 sessionStorage 作为存储位置
  //   paths: ['user']
  // }
})

export default user