import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import myModal from '@/components/common/modal/index.vue'
// import steamLogin from './steamLogin.vue'
import index from './index.vue';
import scroll from '@/utils/noscroll.js';


import { render, h, createApp } from "vue";
export const loginModal = (params, callback) => {
  console.log('params:', params);
  //使用vue方式创建组件
  const div = document.createElement('div')
  document.body.appendChild(div)
  const app = createApp(index, {
    visible: params.visible,
    title: params.title,
    contentType: params.contentType,
    /**
     * 事件监听 触发冲突,要么不监听onclick 直接监听自定义事件,要么给事件添加stopPropagation  阻止事件冒泡
     */
    onClick: () => {
      //点一个复选框 会监听到调用两次 why?
      // console.log('js in onclick');
    },
    onLogin: () => {
      // console.log('response login result');
    },
    onEmail: (val) => {
      console.log('onemail', val);
      callback(() => {
        console.log('c:', val);
      })
    },

    onClose: () => {
      console.log('登录成功或关闭登录');

      //销毁该组件实例
      app.unmount()
      div.remove()

      callback(() => {

        //登录成功或取消登录,更新用户数据
        console.log('pass callback');
      })
    }


  })
  app.provide('scroll', scroll)
  app.component('myModal', myModal)
  app.use(Antd)
  app.mount(div)//挂载


  //使用h+render创建函数组件
  //使用h函数创建vnode
  // const vnode = h(steamLogin)
  // render(vnode,document.body)

}