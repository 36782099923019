import { getAction, postAction, menuGetAction } from "@/request/menage.js";
import a from './exchange.js';
//合约
export default {

  /**
   * 装备合成(升级合约)
   * @param {*} data  选中的合成装备数组
   * @param {*} out_id 目标装备id 
   * @param {*} extent  //roll区间值，数组[min值，max值]刻度超过一百时分两段[min值，max值，min值，max值]

   * @returns 
   */
  doSynthesis:(params)=>postAction('/synthesis',params),

  /**
   * 快速选择升级装备
   * @param {*} data  合成装备 数组
   * @param {*} multiple  升级倍数
   * @returns 
   */
  runList:(params)=>postAction('/run_list',params),

  /**
   * 可合成列表 目标饰品
   * @param min_integral 区间最小金额
   * @param max_integral 区间最大金额
   * @param {*} sort,排序  asc正序(小到大) desc倒序  默认倒序
   * @param name 道具名称
   * @param page 页码
   * @returns 
   */
  getSynthesisList:(params)=>getAction('/synthesis/list',params),

  //升级/合成记录
  synthesisRecord:() => getAction('/synthesis/record'),


  //我的饰品
  getUpgradeStorage:a.getExchangeStorage
}

// {
//   "from_drops": [
//           839458159
//   ],
//   "to_items": [
//           9472
//   ],
//   "start_ticket": 1,
//   "estimated_chance": 54000
// }

//response
// {
//   "id": 70065572,
//   "user_id": 4193478,
//   "item_id": 9472,
//   "roll_id": 354445744,
//   "is_win": true,
//   "goods_type": "drop",
//   "bet_amount": 3,
//   "desired_amount": 5,
//   "multiplier": 1.7,
//   "rand": 15.274,
//   "chance": 54000,
//   "is_rolled_under": false,
//   "start_ticket": 1,
//   "end_ticket": 54000,
//   "created_at": "2024-09-10 08:16:27",
//   "item": {
//           "id": 9472,
//           "market_hash_name": "P2000 | Gnarled (Well-Worn)",
//           "name": "P2000",
//           "finish": "Gnarled",
//           "rarity_site": "Rare",
//           "price": 5,
//           "available": 108,
//           "category": "Skin",
//           "type": "Pistol",
//           "exterior": "Well-Worn",
//           "quality": "Mil-Spec Grade",
//           "rarity": "Rare",
//           "specifics": null,
//           "file": {
//                   "path": "iixekz9zfm/items/e4459a3c27889acb8e3c3d8ebfdc28e23e3dd14f.png"
//           }
//   },
//   "user": {
//           "id": 4193478,
//           "steam_id": "76561199650176724",
//           "name": "Sykut",
//           "avatar": "be19251703a6cbdf402f3ce531cda3f8e5a57c59",
//           "xp": 348640,
//           "is_verified": false,
//           "level_number": 16,
//           "created_at": "2024-04-03 05:48:54"
//   },
//   "won_drop": {
//           "id": 839769548,
//           "user_id": 4193478,
//           "event_id": 70065572,
//           "item_id": 9472,
//           "type": "upgrade",
//           "price": 5,
//           "status": "pending",
//           "action_permission": "any",
//           "flags": 0,
//           "created_at": "2024-09-10 08:16:27",
//           "updated_at": "2024-09-10 08:16:27",
//           "action_remaining_time": 0,
//           "steam_trade_url": null,
//           "can_be_exchanged": false,
//           "item": {
//                   "id": 9472,
//                   "market_hash_name": "P2000 | Gnarled (Well-Worn)",
//                   "name": "P2000",
//                   "finish": "Gnarled",
//                   "rarity_site": "Rare",
//                   "price": 5,
//                   "available": 108,
//                   "category": "Skin",
//                   "type": "Pistol",
//                   "exterior": "Well-Worn",
//                   "quality": "Mil-Spec Grade",
//                   "rarity": "Rare",
//                   "specifics": null,
//                   "file": {
//                           "path": "iixekz9zfm/items/e4459a3c27889acb8e3c3d8ebfdc28e23e3dd14f.png"
//                   }
//           }
//   },
//   "bet_drops": [
//           {
//                   "id": 839458159,
//                   "user_id": 4193478,
//                   "event_id": 310258368,
//                   "item_id": 7476,
//                   "type": "case",
//                   "price": 3,
//                   "status": "upgraded",
//                   "action_permission": "none",
//                   "flags": 0,
//                   "created_at": "2024-09-10 03:10:13",
//                   "updated_at": "2024-09-10 08:16:27",
//                   "action_remaining_time": 0,
//                   "steam_trade_url": null,
//                   "can_be_exchanged": false,
//                   "item": {
//                           "id": 7476,
//                           "market_hash_name": "MP9 | Slide (Field-Tested)",
//                           "name": "MP9",
//                           "finish": "Slide",
//                           "rarity_site": "Common",
//                           "price": 3,
//                           "available": 867,
//                           "category": "Skin",
//                           "type": "SMG",
//                           "exterior": "Field-Tested",
//                           "quality": "Consumer Grade",
//                           "rarity": "Common",
//                           "specifics": null,
//                           "file": {
//                                   "path": "iixekz9zfm/items/2479f332ca10db984e40f43af6f98c2d1ee97522.png"
//                           }
//                   }
//           }
//   ]
// }

//超过100
// {
//   "from_drops": [
//           839458667
//   ],
//   "to_items": [
//           9472
//   ],
//   "start_ticket": 68690,
//   "estimated_chance": 54000
// }
//响应
// {
//   "data": {
//           "id": 70065770,
//           "user_id": 4193478,
//           "item_id": 9472,
//           "roll_id": 354446859,
//           "is_win": true,
//           "goods_type": "drop",
//           "bet_amount": 3,
//           "desired_amount": 5,
//           "multiplier": 1.7,
//           "rand": 88.605,
//           "chance": 54000,
//           "is_rolled_under": false,
//           "start_ticket": 68690,
//           "end_ticket": 22689,
//           "created_at": "2024-09-10 08:20:46",
//           "item": {
//                   "id": 9472,
//                   "market_hash_name": "P2000 | Gnarled (Well-Worn)",
//                   "name": "P2000",
//                   "finish": "Gnarled",
//                   "rarity_site": "Rare",
//                   "price": 5,
//                   "available": 107,
//                   "category": "Skin",
//                   "type": "Pistol",
//                   "exterior": "Well-Worn",
//                   "quality": "Mil-Spec Grade",
//                   "rarity": "Rare",
//                   "specifics": null,
//                   "file": {
//                           "path": "iixekz9zfm/items/e4459a3c27889acb8e3c3d8ebfdc28e23e3dd14f.png"
//                   }
//           },
//           "user": {
//                   "id": 4193478,
//                   "steam_id": "76561199650176724",
//                   "name": "Sykut",
//                   "avatar": "be19251703a6cbdf402f3ce531cda3f8e5a57c59",
//                   "xp": 348700,
//                   "is_verified": false,
//                   "level_number": 16,
//                   "created_at": "2024-04-03 05:48:54"
//           },
//           "won_drop": {
//                   "id": 839775464,
//                   "user_id": 4193478,
//                   "event_id": 70065770,
//                   "item_id": 9472,
//                   "type": "upgrade",
//                   "price": 5,
//                   "status": "pending",
//                   "action_permission": "any",
//                   "flags": 0,
//                   "created_at": "2024-09-10 08:20:46",
//                   "updated_at": "2024-09-10 08:20:46",
//                   "action_remaining_time": 0,
//                   "steam_trade_url": null,
//                   "can_be_exchanged": false,
//                   "item": {
//                           "id": 9472,
//                           "market_hash_name": "P2000 | Gnarled (Well-Worn)",
//                           "name": "P2000",
//                           "finish": "Gnarled",
//                           "rarity_site": "Rare",
//                           "price": 5,
//                           "available": 107,
//                           "category": "Skin",
//                           "type": "Pistol",
//                           "exterior": "Well-Worn",
//                           "quality": "Mil-Spec Grade",
//                           "rarity": "Rare",
//                           "specifics": null,
//                           "file": {
//                                   "path": "iixekz9zfm/items/e4459a3c27889acb8e3c3d8ebfdc28e23e3dd14f.png"
//                           }
//                   }
//           },
//           "bet_drops": [
//                   {
//                           "id": 839458667,
//                           "user_id": 4193478,
//                           "event_id": 310258454,
//                           "item_id": 1312,
//                           "type": "case",
//                           "price": 3,
//                           "status": "upgraded",
//                           "action_permission": "none",
//                           "flags": 0,
//                           "created_at": "2024-09-10 03:10:39",
//                           "updated_at": "2024-09-10 08:20:46",
//                           "action_remaining_time": 0,
//                           "steam_trade_url": null,
//                           "can_be_exchanged": false,
//                           "item": {
//                                   "id": 1312,
//                                   "market_hash_name": "PP-Bizon | Sand Dashed (Field-Tested)",
//                                   "name": "PP-Bizon",
//                                   "finish": "Sand Dashed",
//                                   "rarity_site": "Common",
//                                   "price": 3,
//                                   "available": 89,
//                                   "category": "Skin",
//                                   "type": "SMG",
//                                   "exterior": "Field-Tested",
//                                   "quality": "Consumer Grade",
//                                   "rarity": "Common",
//                                   "specifics": null,
//                                   "file": {
//                                           "path": "iixekz9zfm/items/806705977848480d5387a04f2fac26fe5dfe8260.png"
//                                   }
//                           }
//                   }
//           ]
//   }
// }




// {
//   "from_drops": [
//           839845026
//   ],
//   "to_items": [
//           6411
//   ],
//   "start_ticket": 61966,
//   "estimated_chance": 67500  概率
// }