import { createI18n } from 'vue-i18n'

// 语言包
import messages from './locales'
const i18n = createI18n({
  legacy: false, // 设置为 false，启用 composition API 模式
  locale:localStorage.getItem('lang')||import.meta.env.VITE_LANG,//初始化为中文
  globalInjection:true,//全局注册$t方法
  messages,
})
export default i18n