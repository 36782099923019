<template>
  <div class="custom">
    <!-- 倒计时模态框 -->
    <div class="auto-zc" ref="autoZc"></div>
    <a-modal
      v-model:visible="dd"
      width="300px"
      :title="null"
      :footer="null"
      :getContainer="() => $refs.autoZc"
      @cancel="closeAutoModal"
      transitionName=""
      :bodyStyle="bodyObj[tipstype]"
      :mask="false"
    >
      <div
        class="prograss-bar"
        :style="{ width: progressWidth,backgroundColor:prograssBar[tipstype] }"
        @transitionend="handleTransitionEnd"
      ></div>
      <img v-if="tipstype=='success'" src="@/assets/images/systemicon//success.png" alt="" />
      <img v-else src="@/assets//images/systemicon/warn.png" alt="" />
      <div class="p">
        <span @click="dohan">{{ autotips }}</span>
      </div>
      <template #closeIcon>
        <div class="closeimg">
          <img src="@/assets/images/systemicon/closeauto.png" alt="" />
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script setup>
import { Modal } from "ant-design-vue";
import { ref, computed, watch, onMounted } from "vue";
const props = defineProps(["autotips", "tipstype"]);

//全局提示背景
const bodyObj = {
  default:  {
    background: "linear-gradient(90deg, #fba206 0%, #faaf35 100%)"
  },
  success:  {
    background: "linear-gradient(90deg, #23A228 0%, #1EAF24 100%)"
  },
};
//全局提示倒计时滚动条背景
const prograssBar = {
  default:'#f9d5a2',
  success:'#A8F9A2'
}
const emits = defineEmits(["close", "click"]);
const dd = ref(true);

//倒计时滚动提示条开关
const autoModalVisible = ref(true);
//提示的内容
// const autotips = ref('default')
//滚动进度值
const progress = ref(100);
//滚动条的宽度样式
const progressWidth = ref("100%"); // 初始宽度为100%

// 计算属性，根据进度计算宽度
const computedProgressWidth = computed(() => {
  if (progress.value === 0) {
    // 进度为0时返回特定值
    return "0%";
  }
  // 根据进度计算宽度
  return `${progress.value}%`;
});

// 观察进度变化，更新宽度
watch(computedProgressWidth, (newValue, oldValue) => {
  progressWidth.value = newValue;
});

//倒计时定时器
const countDownTimer = ref(null);
// 处理进度条过渡结束--倒计时结束后的处理
function handleTransitionEnd() {
  if (progressWidth.value === "-10%") {
    // 宽度为0时执行的操作
    // console.log("Progress bar width is now 0%");
    // 可以在这里返回一个值或执行其他逻辑
    // autoModalVisible.value = false;
    //传递回调函数用到
    // emits('close')
    emits('click')
    // autotips.value=''
    initdata();
    clearInterval(countDownTimer.value);
  }
}

// 更新进度条的函数
function updateProgress(newProgress) {
  progress.value = newProgress;
  // console.log("kk:", progress.value);
}
//开启倒计时-暴露出去
const countDown = (auto) => {
  //auto 表示已打开滚动条倒计时提示
  if (auto) {
    // 模拟进度更新
    countDownTimer.value = setInterval(() => {
      if (progress.value > -10) {
        updateProgress(progress.value - 10);
      }
    }, 150);
  }
};
//手动点击关闭按钮,关闭提示条
const closeAutoModal = (e) => {
  console.log("手动关闭提示条", e);
  clearInterval(countDownTimer.value);
  initdata();
  //传递回调函数使用close
  // emits('close')
  emits("click");
};
const dohan = () => {
  // console.log('do');
  // emits('close','close tip')
  closeAutoModal();
};
//初始化数据
const initdata = () => {
  // progress.value=100
  // progressWidth.value='100%'
};
onMounted(() => {
  countDown(true);
});
</script>
<style lang="scss" scoped>
.custom {
}
.auto-zc {
  :deep(.ant-modal) {
    top: 0;

    min-width: 400px !important;
    width: max-content !important;
    // height: 72px;
    height: max-content;
    padding-bottom: 0;
    .ant-modal-content {
      background-color: transparent;
      min-width: 400px !important;
      width: max-content;
    }
    .ant-modal-close-x {
      // border: 2px solid red;
      transform: translate(4%, 14%);
      right: 0;
      .closeimg {
        img {
          width: 10px;
          height: 10px;
        }
      }
    }
    .ant-modal-body {
      display: flex;
      align-items: center;
      position: relative;
      min-width: 400px;
      width: max-content;
      height: 72px;
      // background: linear-gradient(90deg, #fba206 0%, #faaf35 100%);
      border-radius: 5px;
      //进度条
      .prograss-bar {
        // width: 400px;
        transition: width 0.5s linear; /* 添加过渡效果 */
        height: 5px;
        // background-color: #f9d5a2;
        opacity: 0.8;
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
      }
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .p {
        margin-left: 20px;
        margin-right: 20px;
        font-size: 18px;
        display: flex;
        align-items: center;
        color: #fff;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
