import { createRouter, createWebHistory } from "vue-router";

//创建路由器  create a router
const router = createRouter({
  history: createWebHistory(),
  //管理路由 manageRouting
  routes: [//一个个的路由规则
    {
      path: "/",
      redirect: '/box',//重定向到首页
    },
    {
      path:"/api/steam/handle",
      // beforeEnter: (to, from, next) => {
      //   console.log('beforeEnter',to)


      // }

    },
    {
      path: "/box",
      name: 'box',
      component: () => import('@/page/box/index.vue')
    },
    {
      path: "/box/:id",
      name: "boxid",
      component: () => import('@/page/boxdetail/index.vue')
    },
    {
      path: '/upgrade',
      name: 'upgrade',
      component: () => import('@/page/upgrade/index.vue')
    },
    {
      path: '/exchange',
      name: 'exchange',
      meta: {
        needLogin: true
      }
      , component: () => import('@/page/exchange/index.vue')
    },
    {
      path: '/battle',
      name: 'battle',
      component: () => import('@/page/battle/index.vue')
    },
    {
      path: '/create',
      name: 'create',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/createbattle/index.vue')
    },
    {
      path: '/broom/:id',
      name: 'battleRoom',
      component: () => import('@/page/battleroom//index.vue')
    },

    {
      path: '/roll',
      name: 'roll',
      component: () => import('@/page/roll/index.vue'),
    },
    {
      path: '/roll/:id',
      name: 'rolldetail',
      meta: {
        needLogin: true
      },
      component: () => import('@/page/rolldetail/index.vue'),
    },
    {
      path: '/user',
      name: 'user',
      meta: {
        needLogin: !true
      },
      component: () => import('@/page/user/index.vue'),
      children:[{
        path: 'battle',
        component: () => import('@/page/battle/index.vue'),
      }
      ]
    },
    {
      path: '/seed/:rollId',
      name: 'seed',
      component: () => import('@/page/seedsetting/index.vue'),
    },
    {
      path: '/slogin',
      name: 'slogin',
    },{
      path: '/test',
      name: 'test',
      component: () => import('@/page/test/index.vue'),
    }

  ],
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

export default router
