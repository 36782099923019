<!-- 道具为空 -->
<template>
  <div class="empty-wrapper">
    <slot>{{ $t('noSkins') }}</slot>
    <a-button v-if="visible" class="open" @click="toOpenBox">{{ $t('openBoxCase') }}</a-button>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const props = defineProps({
  visible:{
    type:Boolean,
    default:true
  }
})

const router = useRouter();
function toOpenBox() {
  //tag 思考 跳转自动判断是否需要登录
  router.push('/')
}
 
</script>
<style lang="scss" scoped>
.empty-wrapper{
  @extend %flex-all-center;
  flex-direction: column;
  font-weight: bold;
  font-size: .24rem;
  color: #ffffff;
  
  width: inherit;
  height: inherit;
  min-width: 300px;
  min-height: 300px;

  .open{
    margin-top: .5rem;
    border-radius: .23rem;
    border: 1px solid #262D40;
    width: 1.7rem;
    height:.46rem;
    font-size: .2rem;
    font-weight: 400;
    background: #1B2132;
    color: #73829D;
  }
}
</style>