/**
 * @description 根据给定的相对路径图片地址,返回图片路径
 * @param {String} imagepath 相对图片路径地址
 * @returns 图片路径
 */
export function getImageUrlByNewURL(imagepath){
  return new URL(`../assets/images/${imagepath}`,import.meta.url).href
}

//动态获取图片路径  systemicon目录下
function getImageUrl(name) {
  return new URL(`../../../assets/images/systemicon/${name}.png`, import.meta.url).href;

  //bug报错  (intermediate value).globEager is not a function
  // const path = `@/assets/images/systemicon/${name}.png`
  // const modules = import.meta.globEager("../../assets/images/systemicon/*.png")
  // return modules[path].default;
}