import $api from '@/api'
import $store from '@/store'
import { removeToken, setToken, getToken } from './auth'

export default function (api = $api, store = $store) {
  //userStore
  const u = store('user')
  const token = getToken()
  // console.log('auto auth');
  u.$patch(state => {
    token ? state.isToken = true : state.isToken = false
    u.updateUserData()
  })

  // if(token){
  //   u.$patch(state=>{
  //     state.isToken=true
  //     u.updateUserData()
  //   })
  // }else{
  //   u.$patch(state=>{
  //     state.isToken=false
  //     u.updateUserData()
  //   })
  // }
}
