import {
	createPinia
} from 'pinia';//引入pinia
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';//引入持久化插件

const pinia = createPinia()//创建pinia实例
export default pinia//导出pinia实例
// export const setupPinia = (app) => {
// 	// console.log('app:',app);
// 	const pinia = createPinia()//创建pinia实例
// 	// console.log('plugin pinia;',piniaPluginPersistedstate);
// 	pinia.use(piniaPluginPersistedstate)//将插件添加到pinia实例上
// 	app.use(pinia)//注册pinia到vue App上
// }