/**
 * 禁止body滚动
 */
function noScroll() {
  // console.log('弹框后禁止底部滚动');
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}

/**
 * 允许页面滚动
 */
function canScroll(){
  console.log('jiejin');
  var mo = function (e) {
    e.preventDefault()
    }
    document.body.style.overflow = ''// 出现滚动条
    document.removeEventListener('touchmove', mo, false)
}
export default {
  noScroll,
  canScroll
}