import { defineStore } from 'pinia'

export const test = defineStore('test', {
  state: () => {
    return {
      someState: 1,
    }
  },
  persist: {
    key: 'test-user-store', // 自定义存储的键名
    storage: window.localStorage, // 使用 sessionStorage 作为存储位置
    paths: ['someState']
  }
})
export default test