import { defineStore } from "pinia";
import userApi from '@/api/user.js';
import { clone, get, update } from 'lodash';
// import {loginModal} from '@/components/common/login/fnlogin.js'
import { loginModal } from '@/components/common/login'
import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;


//随机生成三位数 介于100-999之间
function getRandomThreeDigitNumber() {
  // return Math.floor(Math.random() * 900) + 100;
  const num  = Math.floor(Math.random() * 900) + 100;
  console.log('san;',num);
  return num
}

const sitePublic = {
  user_number: ''//注册用户数量
  , battles_number: ''//战斗次数
  , upgrades_number: ''//升级次数
  , open_box_number: ''//武器箱
  , online_number: getRandomThreeDigitNumber()//在线人数
}

const siteInfo = defineStore({
  id: 'siteInfo'
  , state: () => ({
    count: 1,
    insertIndex:0,
    currnetIndex:0,
    sitePublic: clone(sitePublic),
    openList:[],//开箱后websock推送过来的新箱子列表(开箱开到的箱子集合)
    hotcase:[],
    windrop:[]
  }),
  actions: {
    //获取站点信息
    getSiteInfo() {
      return userApi.getInfo().then(res => {
        if (res.code != 200) {
          //站点信息获取失败
          console.log('站点信息获取失败');
          return
          //使用默认站点信息
          // return this.resetUserData()
        }
        this.sitePublic = res.data
        return Promise.resolve(res.data)
      })
    },

    //设置顶部轮播箱子列表数据
    setHotcase(hotcase,wincase) {
      this.hotcase = hotcase
      this.windrop = wincase
      console.error('set hot',this.hotcase);
    },

    //更新顶部轮播箱子列表数据(当开箱后 有新的箱子通过websocket推送过来时,更新数据)
    updateHotcase(hotcase,wincase) {
      this.openList.push(...hotcase)
      let insertIndex = this.insertIndex+3;//把推送的添加到可视区域的轮播图的第9个位置
      const totalLen = this.hotcase.length
      console.error('insertIndex',insertIndex,'current;',this.currnetIndex,'totalLen',totalLen,);
      if(insertIndex>totalLen){
        //超出总长度
        insertIndex = insertIndex - totalLen
      }

      this.hotcase.splice(insertIndex, 0, ...hotcase)


      

      // this.hotcase = this.hotcase.concat(hotcase)

      //大于50 放到轮播图的前面三个箱子内
      const newWincase = hotcase.filter(item => {
        if(parseFloat(item.bean) > 50){
          return item
        }
      })
      this.windrop = this.windrop.concat(newWincase)
      console.error('upd site;',this.hotcase);
    },

    //更新站点信息--获取websocket推送的站点数据
     updateSiteInfo(data) {
      if (!data) {//websocket推送数据时，断开或未推送数据，使用info接口的站点数据
        return this.getSiteInfo()
      }
      this.sitePublic = data
    },

    //重置站点数据
    resetSiteInfoData() {
      this.sitePublic = clone(sitePublic)
    },



  },
  persist: {
    key: 'site-store', // 自定义存储的键名
    storage: window.localStorage, // 使用localStorage 作为存储位置
  }
  // persist:{
  //   key: 'user-store', // 自定义存储的键名
  //   storage: window.localStorage, // 使用 sessionStorage 作为存储位置
  //   paths: ['user']
  // }
})

export default siteInfo