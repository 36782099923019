import { computed } from 'vue';
import { getImageUrlByNewURL } from '@/utils/getImageUrl.js';
const bgImgObj = {
  '橙': "orange",
  '黄': "yellow",
  '红': "red",
  '紫': "purple",
  '深蓝': "darkblue",
  '淡蓝': "lightblue",
  '绿': "green",
  '青': "cyan",
  '白': "white",
};
const bgiColor = computed(() => {
  return (lv) => {
    // console.log("lv:", lv_alias, bgImgObj[lv_alias]);
    // return bgImgObj[lv_alias];
    return bgImgObj[lv];
  };
});

/**
 * // tag  品质颜色 
 * 橙 1
 * 红 2
 * 紫 3
 * 蓝 4
 * 白 5
 */



//品质颜色
const qualityColor = [
  {
    id: 1,
    lv: 1,
    lv_alias: "橙",
    // color: "#ee7930",
    color: "#FF9D1E",
    odds: 0,
  },
  {
    id: 2,
    lv: 1,
    lv_alias: "黄",
    // color: "#fdef50",
    color: "#FF9D1E",
    odds: 0,
  },
  {
    id: 3,
    lv: 2,
    lv_alias: "红",
    // color: "#eb3223",
    color: "#FF3D3D",
    odds: 0,
  },
  {
    id: 4,
    lv: 3,
    lv_alias: "紫",
    // color: "#dc3df6",
    color: "#E417E6",
    odds: 0,
  },
  {
    id: 5,
    lv: 4,
    lv_alias: "深蓝",
    // color: "#2a27f5",
    color: "#3DABFF",
    odds: 0,
  },
  {
    id: 6,
    lv: 4,
    lv_alias: "淡蓝",
    // color: "#50b9f9",
    color: "#3DABFF",
    odds: 0,
  },
  {
    id: 7,
    lv: 4,
    lv_alias: "青",
    // color: "#74fab9",
    color: "#3DABFF",
    odds: 0,
  },
  {
    id: 8,
    lv: 4,
    lv_alias: "绿",
    // color: "#80f94d",
    color: "#3DABFF",
    odds: 0,
  },
  {
    id: 9,
    lv: 5,
    lv_alias: "白",
    // color: "#eaeaea",
    color: "#D6D6D6",
    odds: 0,
  },
];

//黄红紫蓝白  品质从高到低
//动态获取道具品质颜色-底部边框颜色
const borderColor = computed(() => {
  return (lv) => {
    // console.log("lv:", lv);
    return qualityColor.filter(v => v.lv == lv)[0]
  };
});

//轮播图背景
const swiperBgImgColor = [
  {
    id: 1,
    lv: 1,
    lv_alias: "黄",
    img: getImageUrlByNewURL("index/swiperyellow.png")
  },
  {
    id: 2,
    lv: 2,
    lv_alias: "红",
    img: getImageUrlByNewURL("index/swiperred.png")
  },
  {
    id: 3,
    lv: 3,
    lv_alias: "紫",
    img: getImageUrlByNewURL("index/swiperpurple.png")
  },

  {
    id: 4,
    lv: 4,
    lv_alias: "蓝",
    img: getImageUrlByNewURL("index/swiperblue.png")
  },
  {
    id: 5,
    lv: 5,
    lv_alias: "白",
    img: getImageUrlByNewURL("index/swiperwhite.png")
  },
  {
    id: 6,
    lv: 4,
    lv_alias: '淡蓝',
    img: getImageUrlByNewURL("index/swiperblue.png")
  },
  {
    id: 7,
    lv: 4,
    lv_alias: '深蓝',
    img: getImageUrlByNewURL("index/swiperblue.png")
  },
  {
    id: 8,
    lv: 4,
    lv_alias: '绿',
    img: getImageUrlByNewURL("index/swiperblue.png")
  },
  {
    id: 9,
    lv: 4,
    lv_alias: '青',
    img: getImageUrlByNewURL("index/swiperblue.png")
  },
  {
    id: 10,
    lv: 1,
    lv_alias: '橙',
    img: getImageUrlByNewURL("index/swiperyellow.png")
  },
]

//道具品质背景图片
const awardBgImgColor = [
  {
    id: 1,
    lv: 1,
    lv_alias: "黄",
    img: getImageUrlByNewURL("openbox/awardQuality/award/yellow.png")
  },
  {
    id: 2,
    lv: 2,
    lv_alias: "红",
    img: getImageUrlByNewURL("openbox/awardQuality/award/red.png")
  },
  {
    id: 3,
    lv: 3,
    lv_alias: "紫",
    img: getImageUrlByNewURL("openbox/awardQuality/award/purple.png")
  },

  {
    id: 4,
    lv: 4,
    lv_alias: "蓝",
    img: getImageUrlByNewURL("openbox/awardQuality/award/blue.png")
  },
  {
    id: 5,
    lv: 5,
    lv_alias: "白",
    img: getImageUrlByNewURL("openbox/awardQuality/award/white.png")
  },
  {
    id: 6,
    lv: 4,
    lv_alias: '淡蓝',
    img: getImageUrlByNewURL("openbox/awardQuality/award/blue.png")
  },
  {
    id: 7,
    lv: 4,
    lv_alias: '深蓝',
    img: getImageUrlByNewURL("openbox/awardQuality/award/blue.png")
  },
  {
    id: 8,
    lv: 4,
    lv_alias: '绿',
    img: getImageUrlByNewURL("openbox/awardQuality/award/blue.png")
  },
  {
    id: 9,
    lv: 4,
    lv_alias: '青',
    img: getImageUrlByNewURL("openbox/awardQuality/award/blue.png")
  },
  {
    id: 10,
    lv: 1,
    lv_alias: '橙',
    img: getImageUrlByNewURL("openbox/awardQuality/award/yellow.png")
  },
]

//开箱结果品质背景图片
const awardBgImgColorResult = [
  {
    id: 1,
    lv: 1,
    lv_alias: "黄",
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/yellow.png")
  },
  {
    id: 2,
    lv: 2,
    lv_alias: "红",
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/red.png")
  },
  {
    id: 3,
    lv: 3,
    lv_alias: "紫",
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/purple.png")
  },

  {
    id: 4,
    lv: 4,
    lv_alias: "蓝",
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/blue.png")
  },
  {
    id: 5,
    lv: 5,
    lv_alias: "白",
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/white.png")
  },
  {
    id: 6,
    lv: 4,
    lv_alias: '淡蓝',
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/blue.png")
  },
  {
    id: 7,
    lv: 4,
    lv_alias: '深蓝',
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/blue.png")
  },
  {
    id: 8,
    lv: 4,
    lv_alias: '绿',
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/blue.png")
  },
  {
    id: 9,
    lv: 4,
    lv_alias: '青',
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/blue.png")
  },
  {
    id: 10,
    lv: 1,
    lv_alias: '橙',
    img: getImageUrlByNewURL("openbox/awardQuality/openaward/yellow.png")
  },
]




//开箱 道具背景图片(开箱背景图片和滚动背景图片)
export const awardBgi = computed(() => {
  return (lv) => {
    // return awardBgImgColor[lv_alias]
    // const result = awardBgImgColor.filter(v=>v.lv_alias==lv_alias)[0].img
    // console.log('lv:',lv_alias);
    // console.log('s:',result);
    // return awardBgImgColor.filter(v=>v.lv_alias==lv_alias)[0].img
    console.log('mlv;', lv);
    if(!lv)return ''
    return awardBgImgColor.filter(v => v.lv == lv)[0].img
  }
})

//动态获取开箱结果背景图片
export const awardBgiResult = computed(() => {
  return (lv) => {
    // return awardBgImgColor[lv_alias]
    // const result = awardBgImgColorResult.filter(v=>v.lv_alias==lv_alias)[0].img
    // console.log('lv:',lv_alias);
    // console.log('s:',result);
    // return awardBgImgColorResult.filter(v=>v.lv_alias==lv_alias)[0].img
    return awardBgImgColorResult.filter(v => v.lv == lv)[0].img
    return ''
  }
})

//动态获取轮播图品质背景图片
export const swiperBgi = computed(() => {
  return (lv) => {

    return swiperBgImgColor.filter(v => v.lv == lv)[0].img
  }
})

//颜色 lv 品质颜色   1橙  2红  3紫  4蓝  5白

export const qualityShotName = computed(() => {
  return (dura_alias) => {
    //返回dura_alias变量对应的单词首字母并大写
    const arr = dura_alias.split(/[-\s]+/)
    // console.log('arr;',arr);
    //根据-或空格截取字符串

    let result = ''
    arr.forEach(v => {
      // console.log('v;',v);
      result += v.slice(0, 1)
    })
    // console.log('result:',result);
    return result
  }
})

//动态获取道具品质颜色(设置底部边框)
export const dynamicAwardColor = computed(() => {
  return (award) => {
    return borderColor.value(award.lv).color
  }
})

//根据道具品质动态获取对应类型的数据
const chatMap = {
  b: borderColor,//道具品质边框颜色
  bg: awardBgi,//道具品质背景图片'
  swbg: swiperBgi,//轮播图品质背景图片
  s: awardBgiResult,//开箱结果品质背景图片
}


const awardColor = {
  bgi: bgiColor,//返回背景色(背景图片用)
  border: borderColor,//返回道具品质颜色(边框用)
  swiperBgi: swiperBgi,//返回轮播图品质背景图片(轮播图用)
}

// //根据道具品质 根据传入类型,动态获取道具对应品质数据
// export const dw = computed(() => {
//   return (name) => {
//     return chatMap[name]
//   }
// })


export default (bgName) => {
  return awardColor[bgName]
} 