const TokenKey = 'token'
const localLang = 'localLang'
export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function setToken(token) {
  return localStorage.setItem(TokenKey,token)
}
export function removeToken() {
  return localStorage.removeItem(TokenKey)
}
export function getLang() {
  return localStorage.getItem(localLang)
}