import instance from "./index.js"

export function getAction (url, params) {
  return new Promise((resolve, reject) => {
    instance.get(url, params)
      .then((res) => {
        // console.log('get res:',res);
        resolve(res.data)
      })
      .catch((err) => {
        // console.log('get err:',err);
        reject(err.data)
      })
  })
}

export function postAction (url, params) {
  return new Promise((resolve, reject) => {
    instance.post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
export function postAction2 (url, params,config) {
  return new Promise((resolve, reject) => {
    instance.post(url, params,config)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function menuGetAction (url, data) {
  let str = ''
  let value = ''
  Object.keys(data).forEach(item => {
    str += item
    value += data[item]
    url = `${url}?${str}=${value}`
  })

  return new Promise((resolve, reject) => {
    instance.get(url)
      .then(res => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}