// import $store from '@/ud/store';
// const userStore = $store('user')
import showNotice from '@/components/common/notice/notice.js'
/**
 * 处理响应请求,成功返回res.data  失败返回false
 * @param {*} res 请求返回res
 * @returns 
 */
export default function handleResponse(res) {
	if (res.code == 200) {
		return res.data||res.message
	} else if(res.code==401) {
		// userStore.$patch(state => {
		// 	state.isLogin2 = state.islogin
		// })
		showNotice('f',{desc:res.message})
		return false
	}else{
		// showNotice('f',{desc:res.message})
		return false
	}
}
