<template>
  <div class="wrapper">
    <div class="navbar-top">
      <div class="nav-l">
        <div class="nav-top-item online">
          <img src="@/assets/images/systemicon/online.png" alt="" />
          <span class="fwb fz16">{{ sitePublic.online_number }}</span>
        </div>
        <div class="nav-top-item fz14 fw4 faq">{{ $t("faq") }}</div>
        <div class="nav-top-item fair fz14 fwb">
          {{ $t("fairnessVerification") }}
          <img src="@/assets/images/systemicon/verify.png" alt="" />
        </div>
        <div class="nav-top-item"></div>
      </div>
      <div class="nav-r">
        <!-- <div class="langrage" @click="click"></div> -->
        <cDropDown dropdownPlacement="bottomLeft">
          <template #title>
            <div>
              <img class="currentimg" :src="currentLangImg" alt="" />
              <!-- &#9660; -->
              <div class="jb"></div>
            </div>
          </template>
          <template #cmenus>
            <a-menu>
              <a-menu-item v-for="lanitem in langList" :key="lanitem.key">
                <div class="lang-item" @click="selected(lanitem)">
                  <img :src="lanitem.nationalFlag" alt="" />
                  <span>{{ lanitem.lang }}</span>
                </div>
              </a-menu-item>
            </a-menu>
          </template>
        </cDropDown>
        <template v-if="u.isToken">
          <div class="siteinfo info" @click="showMsg">
            <img src="@/assets/images/systemicon/siteinfo.png" alt="站内信" />
            <div class="noti-dot"></div>
          </div>
          <div class="siteinfo tui" @click="logout">
            <img src="@/assets/images/systemicon/quit.png" alt="退出登录" />
          </div>
        </template>
      </div>
    </div>
    <div class="navbar">
      <div class="logo">
        <img src="@/assets/images/systemicon/logo.svg" alt="" />
      </div>
      <div class="nav-list">
        <router-link
          :class="{ activeNavItem: u.navi == navitem.name }"
          class="nav-item link_a"
          :style="{
            '--bgi':
              u.navi == navitem.name
                ? 'url(' + navitem.selectedImagePath + ')'
                : 'url(' + navitem.imagePath + ')',
          }"
          :to="navitem.path"
          v-for="(navitem, index) in navList"
          :key="navitem.path"
          @click="clickNav(navitem, index)"
          >{{ $t(navitem.navTitle) }}
          <div :class="{ activeLine: u.navi == navitem.name }"></div>
        </router-link>
      </div>
      <div class="account" v-if="u.isToken">
        <a-button class="btn fwb">
          {{ $t("refill") }}
          <template #icon>
            <img src="@/assets/images/systemicon/pay.png" alt="" />
          </template>
        </a-button>
        <div class="user" @click="toMine">
          <div>
            <div class="u-name fz14 fw4">{{ userInfo.name }}</div>
            <!-- <div class="balance fz18 fwb">{{ parseFloat(userInfo.bean).toFixed(2) }}</div> -->
            <div class="balance fz18 fwb">{{ parseFloat(bean.num).toFixed(2) }}</div>
            <!-- 减去 -->
            <div class="updateAccount">
              <span class="balance1">扣{{ updateStr }}</span>
            </div>
          </div>
          <div class="avatar-wp">
            <img :src="$dimg(userInfo.avatar)" alt="" />
          </div>
        </div>
      </div>
      <a-button v-else class="login" @click="login">
        {{ $t("loginLarge") }}
        <template #icon>
          <img src="@/assets/images/index/login_steam.png" alt="" />
        </template>
      </a-button>
    </div>
  </div>
</template>
<script setup>
import i18n from "@/i18n";
const { t: $t, locale } = i18n.global;
import { useLangStore } from "@/store/module/i18n.js";
import {
  ref,
  getCurrentInstance,
  nextTick,
  h,
  watch,
  onMounted,
  reactive,
  computed,
  watchEffect,
} from "vue";
import nationImgModal from "@/utils/dynamicImg.js";
import cDropDown from "@/components/common/dropdown/index.vue";
import $store from "@/store";
import { storeToRefs } from "pinia";
// import { loginModal } from "@/components/common/login/fnlogin.js";
import { loginModal } from "@/components/common/login";

import { useRouter } from "vue-router";
// 语言包
import langPkg from "@/i18n/locales";
import ColumnGroup from "ant-design-vue/lib/vc-table/sugar/ColumnGroup";
import { notification } from "ant-design-vue";
import { SmileOutlined } from "@ant-design/icons-vue";
import { getImageUrlByNewURL } from "@/utils/getImageUrl.js";
import { gsap } from "gsap";
import computeNumber from "@/utils/calcTotal.js";

//userStore
const u = $store("user");
const siteStore = $store("siteInfo");
const {sitePublic} = storeToRefs(siteStore);
const { userInfo } = storeToRefs(u);
const bean = reactive({
  // num:userInfo.bean
  num: u.userInfo.bean || 0,
});
watch(
  () => u.userInfo.bean,
  (newval, oldval) => {
    gsap.to(bean, {
      duration: 0.5,
      num: newval,
      onComplete() {
        console.log("ddd;", u.userInfo.bean);
      },
    });
  }
);

const router = useRouter();
watch(
  () => router.currentRoute.value.path,
  (toPath) => {
    //要执行的方法
    // console.log("router.currentRoute", router.currentRoute.value.name);
    u.navi = router.currentRoute.value.name;
  },
  { immediate: true, deep: true }
);

// const { t:$t, locale } = i18n.global;
const lanStore = useLangStore();
const locLang = ref(lanStore.language);
//js中获取当前语言
// console.log('lanL:',i18n.global.t('login'));
// console.log('$t lanL:',$t('login'));
// console.log('lanL:',i18n.global.t('login'));
// console.log('lanL:',i18n.global.t('loginobj'));
const { proxy } = getCurrentInstance();

const updateCount = ref({ type: "increment", val: 0 });
const updateStr = computed(() => {
  let type = "";
  switch (updateCount.value.type) {
    case "increment":
      type = "+";
      break;
    // return '-'+dd.value.val;
    case "decrement":
      type = "-";
      break;
    // return 100;
  }
  return type + "$ " + updateCount.value.val;
});
proxy.$EventBus.on("upd", (val) => {
  // console.log("减一", val);
  updateCount.value = val;
  // console.log("ub:", u.userInfo.bean);

  //短时间内多次执行扣费动画 如何优化
  gsap.from(".updateAccount", {
    y: 20,
    duration: 1.2,
    opacity: 1,
    delay: 0.1,
    onComplete() {
      console.log("扣费结束");
      gsap.set(".updateAccount", { y: 0, opacity: 0 });
    },
  });
});


//测试并发扣费
// const isClick = ref(false);
// function testDeduction() {
//   console.log("扣除");
//   if (isClick.value) return;
//   isClick.value = true;
//   proxy.$EventBus.emit("upd", { type: "decrement", val: 100 });
//   gsap.delayedCall(1, () => {
//     isClick.value = false;
//   });
// }

const noticeCount = ref(0);
//测试全局提示
function showMsg() {
  // console.log("test all tips");
  proxy.$notice("s", {
    desc:
      "This is the content of the notification.This is the content of the notification.This is the content of the notification.This is the content of the notification.",
  });
  // notification.error({
  //   placement:'bottomLeft',
  //   class: "cus-err",
  //   key:noticeCount.value,
  //   message: "title"+noticeCount.value,
  //   description: "This is the content of the notification. ",
  //   onClose:()=>{
  //     console.log('notice is close');
  //   },
  //   icon: () =>
  //     h("img", {
  //       src: getImageUrlByNewURL("systemicon/notice_error.png"),
  //       style: "color: #108ee9",
  //       class: "notice-icon",
  //     }),
  // });
  // noticeCount.value++;
}
// watch(
//   () => noticeCount.value,
//    () => {
//     nextTick(()=>{
//       console.log('count;',noticeCount.value);
//       const cusErrLen = document.getElementsByClassName("cus-err");

//       //watch dom.length
//       console.log("cuserrlen;", cusErrLen);
//       console.log("len:", cusErrLen.length);
//       console.log('html;',cusErrLen[cusErrLen.length-1]);

//     })
//   }
// );

//可使用的语言
const langList = ref({});
for (const key in langPkg) langList.value[key] = key;
//语言相关配置
const langArr = [
  {
    key: "zh-cn",
    lang: "简体中文",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/zh-cn.png"),
  },
  {
    key: "en-gb",
    lang: "English",
    // nationalFlag: proxy.$getImageUrlByNewURL("systemicon/nationalflag/en-gb.svg"),
  },
];
for (const obj of langArr) {
  // console.log("key:", obj.key);
  for (const key in nationImgModal) {
    if (key == obj.key) {
      obj.nationalFlag = nationImgModal[key];
    }
  }
}
//根据系统语言包,生成最终需要的语言相关数据,供给用户选择要使用的语言
langArr.forEach((e) => {
  if (langList.value[e.key] == e.key) {
    langList.value[e.key] = e;
  }
});
const currentLangImg = ref();
// console.log("langlist after:", langList.value);

for (const key in langList.value) {
  // console.log("key:", key);
  if (lanStore.language == key) {
    currentLangImg.value = langList.value[key].nationalFlag;
    break;
  } else {
  }
}
// currentLangImg.value = langList.value.filters((v) => {
//   if (lanStore.language == v.key) {
//     return v.nationalFlag;
//   } else {
//     return langArr.filter((v) => {
//       if (v.key == "en-gb") {
//         return v.nationalFlag;
//       }
//     });
//   }
// });

// console.log("dangqian:", lanStore.language);
//切换语言

const selected = (lanitem) => {
  console.log("cL:", lanitem);
  currentLangImg.value = lanitem.nationalFlag;
  lanStore.changeLang(lanitem.key);
};

//切换语言
const click = () => {
  console.log("click");
  if (locLang.value == "en-gb") {
    locLang.value = "zh-cn";
  } else {
    locLang.value = "en-gb";
  }
  lanStore.changeLang(locLang.value);
};

//导航栏数据 $myt
const navList = ref([
  {
    navTitle: "navBox",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/box.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/boxSelected.png"),
    path: "/box",
    name: "box",
    checked: true,
  },
  {
    navTitle: "navBattles",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/battle.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/battleSelected.png"
    ),
    path: "/battle",
    name: "battle",
    checked: false,
  },
  {
    navTitle: "navRoll",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/roll.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/rollSelected.png"),
    path: "/roll",
    name: "roll",
    checked: false,
  },
  {
    navTitle: "navUpgrade",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/contract.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/contractSelected.png"
    ),
    path: "/upgrade",
    name: "upgrade",
    checked: false,
  },
  {
    navTitle: "navExchange",
    imagePath: proxy.$getImageUrlByNewURL("systemicon/navicon/contract.png"),
    selectedImagePath: proxy.$getImageUrlByNewURL(
      "systemicon/navicon/contractSelected.png"
    ),
    path: "/exchange",
    name: "exchange",
    checked: false,
  },
]);

//选中导航栏
const clickNav = (navitem, index) => {
  if (u.navi == index) return;
  u.navi = navitem.name;
  // if (navitem.checked) return;
  // console.log("current navlist:", navitem.path);
  // navitem.checked = true;
  // navList.value.map((v) => {
  //   if (v.path != navitem.path) {
  //     v.checked = false;
  //   }
  // });
};

//前往用户中心
function toMine() {
  router.push("/user");
}

//显示登录弹框
function login() {
  loginModal(
    { visible: true, title: $t("loginLarge"), contentType: "def" },
    (callnack) => {
      // console.log('未登录 弹登录框');
      callnack();
    }
  );
}

//退出登录
function logout() {
  u.logout();
  router.push("/");
}
</script>
<style lang="scss" scoped>
@import "./index.scss";

//自定义组件样式
:global(.ant-dropdown-menu) {
  border: 1px solid #262d40;

  width: 220px;
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 14px;
  background-color: $app-bg-color !important;
  @extend .fw4;
  @extend .fz14;
}
:global(.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected) {
  background-color: $app-bg-color !important;
}
.ant-dropdown-menu-item {
  color: #6c748a !important;
  .ant-dropdown-menu-title-content {
    border: none;
  }
  .lang-item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 20px;
    width: 190px;
    height: 50px;
    border-bottom: 1px solid #262d40;
    img {
      width: 27px;
      height: 18px;
      margin-right: 21px;
    }
    span {
      color: #6c748a;
      @extend .fw4;
    }
  }
}
:global(.ant-dropdown-menu-item):hover {
  // background-color: #f00 ;
}

//u全局提示
:global(.ant-notification) {
  // border: 2px solid red;
}
:global(.ant-notification-notice) {
  overflow: visible;
  min-height: 100px;
  border-radius: 5px;
  // width: max-content;
  // border: 2px solid rgb(103, 238, 69);
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding: 16px 24px 16px 0;

  //success
  // background: linear-gradient(90deg, #2f1117 0%, #1b2031 100%);
  //fail
  // background: linear-gradient(90deg, #112F15 0%, #1B2031 100%);
}
:global(.ant-notification-notice-message) {
  //QUES  why 在内部不能获取样式,必须提出到外部global
  font-size: 16px;
  font-weight: bold;
  //success
  // color: #3fd03b;
  //fail
  // color: #d03b68;
}
//自定义icon
:global(.ant-notification-notice-icon) {
  left: 0;
  top: 50%;
  transform: translate(-60%, -50%);
}
//关闭icon
:global(.ant-notification-notice-close) {
  color: #ffffff;
  :hover {
    color: #ffffff !important;
  }
}
</style>
