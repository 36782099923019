import { getAction, postAction, menuGetAction } from "@/request/menage.js";
//roll房
export default {

  /**
   * roll 房间列表
   * @param {*} page 页码 
   * @param {*} page_size 页面条数
   * @param {*} status 状态(1:进行中;2:已结束) 
   * @returns 
   */
  rollList: (params) => getAction('/rooms', params),

  //bug 请求自定义配置 判断是否需要登录权限
  /**
   * 个人roll房参加记录
   * @param {*} page 页码 
   * @returns 
   */
  personRollRecord: (params) => getAction('/user/rooms', params),

  /**
   * roll房详情
   * @param {*} id  房间id 
   * @returns 
   */
  rollRoomDetail: (params) => getAction('/room/detail', params),

  /**
   * 创建roll装备列表
   * @param {*} page 页码 
   * @returns 
   */
  rollStorageList: (params) => postAction('/room/storage', params),

  /**
   * 创建roll 房间
   * @param {*} name  房间名 
   * @param {*} pay_start_time  累计充值开始时间（min_recharge>0 需要填写，若没有充值金额限制则可不填写该参数，该参数必须小于结束时间
   * @param {*} end_time, 结束时间 
   * @param {*} people_number,限定人数 
   * @param {*} box_record_ids, 奖品 
   * @param {*} describe, 房间描述 
   * @param {*} password, 房间密码（最大16位数 
   * @param {*} min_recharge, 最小充值金额 
   * @param {*} me_inviter 是否房主邀请才能进入 0：否，1：是 
   * @returns 
   */
  createRollRoom: (params) => postAction('/room/save', params),

  /**
   * 加入roll 房
   * @param {*} id 房间id 
   * @param {*} password 房间密码 
   * @returns 
   */
  joinRollRoom:(params)=>postAction('/room/join',params),
 
}