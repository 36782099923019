import { getAction, postAction, menuGetAction } from "@/request/menage.js";

//置换
export default {
  
  //置换页面需要登录后访问  因为接口数据拿的是用户个人数据
  /**
   * 可合成/置换 装备列表 我的饰品
   * @param {*} page 页面页码 
   * @param {*} sort,排序  asc正序(小到大) desc倒序  默认倒序
   * @returns  post
   */
  getExchangeStorage:(params)=>postAction('/synthesis/storage',params),

  /**
   * 可置换列表 post 目标饰品
   * @param {Array} data  选中的可置换装备(数组)
   * @param {*} page  页码
   * @returns 
   */
  getExchangeList:(params)=>postAction('/metathesis_list',params),

  /**
   * 置换
   * @param {Array} data 选中的可置换装备(数组) 
   * @param {*} out_id 目标装备 
   * @returns 
   */
  doExchange:(params)=>postAction('/metathesis',params)



}