
import { createApp } from "vue";
import scrollTipsBar from "./scrollTipsBar.vue";
import { Button, Modal } from 'ant-design-vue';
function showMessage(msg, clickHandler) {
  // console.log('调用showmessage 全局组件');
  // console.log('显示的数据:', msg);


  //note 渲染滚动提示组件
  const div = document.createElement('div')
  document.body.appendChild(div)
  const app = createApp(scrollTipsBar, {
    autotips: msg,
    tipstype: 'default',
    //不传回调函数
    onClick: (val) => {
      // console.log('auto close:', val);
      app.unmount(div)//卸载掉创建的vue实例
      div.remove()
    },


    //传递回调函数用到
    // onClose: (val) => {
    //   // console.log('组件事件', val);
    //   //貌似modal组件自己的关闭方法会销毁实例,所以emits 值为undefined
    //   console.log('cl:',clickHandler)
    //   clickHandler && clickHandler(() => {
    //     console.log('emits 值:', val);
    //     app.unmount(div)//卸载掉创建的vue实例
    //     div.remove()
    //   })
    // }

  })
  app.use(Button)
  app.use(Modal)
  app.mount(div)


}

function success(msg, clickHandler) {
  // console.log('调用showmessage 全局组件');
  // console.log('显示的数据:', msg);


  //note 渲染滚动提示组件
  const div = document.createElement('div')
  document.body.appendChild(div)
  const app = createApp(scrollTipsBar, {
    autotips: msg,
    tipstype: 'success',
    //不传回调han'shu
    onClick: (val) => {
      // console.log('auto close:', val);
      app.unmount(div)//卸载掉创建的vue实例
      div.remove()
    },


    //传递回调函数用到
    // onClose: (val) => {
    //   // console.log('组件事件', val);
    //   //貌似modal组件自己的关闭方法会销毁实例,所以emits 值为undefined
    //   console.log('cl:',clickHandler)
    //   clickHandler && clickHandler(() => {
    //     console.log('emits 值:', val);
    //     app.unmount(div)//卸载掉创建的vue实例
    //     div.remove()
    //   })
    // }

  })
  app.use(Button)
  app.use(Modal)
  app.mount(div)


}


const customMessage = {
  showMessage,
  success
}
export default customMessage;




