const files = import.meta.glob('./*.js',{ eager: true });
let api = {};
Object.keys(files).forEach((key) => {
	// console.log('files:', files, 'key:', key)
	api = {
		...api,
		[key.replace(/(.*\/)*([^.]+).*/gi, '$2')]: files[key].default,
	};
});
// console.log('api:',api);
export default api;