//导入国旗图片
const nationFlag = import.meta.glob('@/assets/images/systemicon/nationalflag/*.*', { eager: true })

// console.log('动态导入图片:',nationFlag);

const imagesMap = {}

// 循环所有图片，将图片名设置成键，值为导入该图片的地址
for (const key in nationFlag) {
  // let name = key.replace(/(\.\/images\/|\..*)/g, '')
  let name = key.split('/').slice(-1)[0].split('.')[0]

  // 抛出图片大对象后，文件页面直接引入后将图片的具体名称作为属性就能导入该图片
  imagesMap[name] = nationFlag[key].default
}
export default imagesMap