import { getImageUrlByNewURL } from "@/utils/getImageUrl.js";
import { notification } from "ant-design-vue";
import { h, watch, ref, nextTick } from 'vue'

const noticeCount = ref(0)

watch(
  () => noticeCount.value,
  () => {
    nextTick(() => {
      // console.log('count;', noticeCount.value);

      //all notice element in document 
      const cusNotices = document.getElementsByClassName("cus-notice")

      // console.log('cuslen:', cusNotices.length);
      
      // console.log('isclear;',isClearAll);
      if (cusNotices.length > 1) {
        //create clear all  element
        clearAllNotices()
      } else {
        // console.log('remove', noticeCount.value);
        const isClearAll = document.querySelector('.clear')
        if (isClearAll) {
          const wrap = document.querySelector('.clear').parentNode
          wrap.removeChild(isClearAll)
        }
      }

    })
  }
);

//显示清除所有notice 按钮
function clearAllNotices() {
  const isClearAll = document.querySelector('.clear')
  const wrap = document.querySelector('.cus-notice').parentNode
  if (isClearAll) {
    wrap.removeChild(isClearAll)
  }
  const list = wrap.children
  //get last child of list
  const o = list[list.length - 1]

  //create closeAll dom
  const e = document.createElement('div')
  //add click event
  e.addEventListener('click', () => {
    console.log('hide all is click');
    noticeCount.value = 0
    e.parentNode.innerHTML = ''
  })
  e.className = 'clear'
  e.innerHTML = 'Hide all'
  wrap.insertBefore(e, o.nextSibling)
}


/**
 * 全部提示
 * @param {*} type 提示类型 s=success   f=fail
 * @param {*} options 传递的参数对象
 */
export default function (type = 's', options) {
  notification.config({
    placement: options.placement||'topRight',
    // duration: null,
    // key:u.nc,
  })

  //成功提示
  if (type == 's') {
    notification.success({
      message: h('div', {
        class: 'ntitle',
        style: {
          color: '#3fd03b'
        }
      }, 'SUCCESS'),
      description: options.desc,
      class: 'cus-notice',
      style: {
        background: 'linear-gradient(90deg, #112F15 0%, #1B2031 100%)',
      },
      icon: () =>
        h("img", {
          src: getImageUrlByNewURL("systemicon/notice_success.png"),
          style: "color: #108ee9",
          class: "notice-icon",
        }),
      onClose: () => {
        console.log('success notice is auto close');
        noticeCount.value--
      }
    })
  }

  //失败提示
  if (type == 'f') {
    notification.error({
      message: h('div', {
        class: 'ntitle',
        style: {
          color: '#d03b68'
        }
      }, 'ERROR'),
      description: options.desc,
      class: 'cus-notice',
      style: {
        background: 'linear-gradient(90deg, #2f1117 0%, #1b2031 100%)'
      },
      icon: () =>
        h("img", {
          src: getImageUrlByNewURL("systemicon/notice_error.png"),
          style: "color: #108ee9",
          class: "notice-icon",
        }),
      onClose: () => {
        // console.log('error notice is auto close');
        noticeCount.value--
      }
    })
  }

  Promise.resolve(() => {
  }).then(res => {
    // clearAllNotices()
  })

  //触发一个提示,累加一次,通过监听noticeCount 用来控制是否显示 清除所有元素按钮
  noticeCount.value++

}